import * as React from 'react';
import { Link } from 'gatsby';
import { article } from '../styles/layout.css';
import Layout from '../components/layout';

// markup
const NotFoundPage = () => (
  <Layout pageTitle='Not Found'>
    <article className={article}>
      <h1>Page not found</h1>
      <p>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        I couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />

        <Link to="/">Go home?</Link>

      </p>
    </article>
  </Layout>
);

export default NotFoundPage;
